/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'publish': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" d="M6.607 13.543V11.88c0-.886-.718-1.605-1.605-1.605a.605.605 0 01-.418-1.042l2.888-2.764a2.21 2.21 0 013.056 0l2.888 2.764a.605.605 0 01-.418 1.042c-.887 0-1.605.719-1.605 1.605v1.664a2.27 2.27 0 01-2.27 2.27h-.247a2.27 2.27 0 01-2.269-2.27z" _stroke="#fff"/><rect pid="1" x="3" y=".75" width="12" height="2.25" rx="1.125" _fill="#fff"/>'
  }
})
